import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HelpService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class HelpStoreEffects {
  constructor(
    private dataService: HelpService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() => {
        return this.dataService.load().pipe(
          map((data) => {
            return fromActions.loadSuccess({
              data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );
}
