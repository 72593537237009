import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getGuideSectionsToView = (state: State): any[] =>
  state.guide_sections_to_view;

export const selectHelpState = createFeatureSelector<State>('help');

export const selectHelpError = createSelector(selectHelpState, getError);

export const selectHelpIsLoading = createSelector(
  selectHelpState,
  getIsLoading,
);

export const selectGuideSectionsToView = createSelector(
  selectHelpState,
  getGuideSectionsToView,
);
