import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { data }) => ({
    ...state,
    ...data,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.setSectionsToView, (state, { guide_sections_to_view }) => {
    return {
      ...state,
      guide_sections_to_view,
    };
  }),

  on(fromActions.resetGuideSectionsToView, (state) => {
    return {
      ...state,
      guide_sections_to_view: [],
    };
  }),

  on(fromActions.resetState, () => fromState.initialState),
);

export function HelpReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
