export interface State {
  guide_sections_to_view: any[];
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  guide_sections_to_view: null,
  isLoading: false,
  error: null,
};
