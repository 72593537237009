import { createAction, props } from '@ngrx/store';

export const setSectionsToView = createAction(
  '[Help] Set Sections To View',
  props<{ guide_sections_to_view: string[] }>(),
);

export const loadRequest = createAction('[Help] Load Request');

export const loadSuccess = createAction(
  '[Help] Load Success',
  props<{ data: any }>(),
);

export const loadFailure = createAction(
  '[Help] Load Failure',
  props<{ error: any }>(),
);

export const resetGuideSectionsToView = createAction(
  '[Help] Reset Guide Sections To View',
);

export const resetState = createAction('[Help] Reset State');
